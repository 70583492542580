/* @font-face {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: normal;
    src: url('../../../../public/fonts/PublicSans/PublicSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'PublicSans';
    font-style: italic;
    font-weight: normal;
    src: url('../../../../public/fonts/PublicSans/PublicSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: bold;
    src: url('../../../../public/fonts/PublicSans/PublicSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PublicSans';
    font-style: medium;
    font-weight: medium;
    src: url('../../../../public/fonts/PublicSans/PublicSans-Medium.ttf') format('truetype');
}

body {
    font-family: 'PublicSans', sans-serif !important;
}

.my-font {
    font-family: 'PublicSans', sans-serif !important;
} */

/* @font-face {
    font-family: 'PublicSans';
    src: url('../../../../public/fonts/thaisanslite/thaisanslite_r1.ttf') format('truetype');
} */

@font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: normal;
    src: url('../../../../public/fonts/Prompt/Prompt-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Prompt';
    font-style: bold;
    font-weight: bold;
    src: url('../../../../public/fonts/Prompt/Prompt-Medium.ttf') format('truetype');
}

body {
    font-family: 'Prompt', sans-serif !important;
}

.my-font {
    font-family: 'Prompt', sans-serif !important;
}

.fs-1,
.fs-2,
.fs-3,
.fs-4,
.fs-5 {
    font-family: 'Prompt', sans-serif !important;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.center-screen-2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bg-defalt {
    background-color: #F5F7F8;
}

.bg-table-gradient {
    background: linear-gradient(to right, #05A96A, #45B7C4) !important;
}

.btn-login {
    width: 100%;
    background: linear-gradient(to right, #05A96A, #45B7C4);
    color: white !important;
}

.bg-tab {
    background-color: rgba(128, 128, 128, 0.1);
    padding: 2px;
    border-radius: 15px;
}

.btn-tab {
    background-color: white;
    color: grey;
    border: none;
    padding: 10px;
    border-radius: 15px;
}

.btn-tab-seleted {
    background-color: rgb(44, 174, 255);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 15px;
}

.btn-tab-2 {
    background-color: white;
    color: grey;
    border: 1px solid #04BB73;
    padding: 5px;
    border-radius: 8px;
}

.btn-tab-seleted-2 {
    background-color: #04BB73;
    color: white;
    border: 1px solid #04BB73;
    padding: 5px;
    border-radius: 8px;
}

.bg-01A867 {
    background-color: #01A867 !important;
}

.text-01A867 {
    color: #01A867 !important;
}

.custom-rounded-table {
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
}

.custom-card {
    height: 185px !important;
}

.custom-card-2 {
    height: 185px !important;
    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 5px;
    border-radius: 10px;
}

/* Error Page */
.error-page {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(114, 114, 114);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Home Page */
.home-desktop-header {
    position: absolute;
    top: 10%;
}

/* color */
.text-6F6B7D {
    color: #6F6B7D !important;
}

/* user-data */
.user-data {
    display: flex;
    flex-flow: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    /* border: 1px solid #6f6b7d6f !important; */
    background: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    border-radius: 15px;
}

.nevbar-moblie {
    font-size: 16px !important;
}

.nevbar-moblie button {
    font-size: 16px !important;
}

.custom-modal {
    width: 30vw;
}

/* close focus */
input:focus {
    outline: none;
}

button:focus {
    outline: none;
}

.bg-invoice {
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.bg-invoice-crad {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.btn-go-home {
    background: linear-gradient(to right, #FB3335, #B5191A);
    border-radius: 25px !important;
    max-height: 30px;
}

.bg-white-50 {
    background: rgba(255, 255, 255, 0.562);
}

@media screen and (max-width: 1120px) {
    .custom-modal {
        width: 60vw;
    }
}

/* card */
@media screen and (max-width: 700px) {
    .card .card-header {
        padding: 5px;
    }

    .custom-card {
        height: 140px !important;
    }

    .text-tab .fs-5 {
        font-size: 16px !important;
    }

    .custom-card .fs-5 {
        font-size: 16px !important;
    }

    .custom-card .fs-1 {
        font-size: 24px !important;
    }

    .user-data {
        flex-flow: column;
        row-gap: 0.2rem;
        column-gap: 0.2rem;
    }

    .user-data .fs-3 {
        font-size: 22px !important;
    }

    .user-data .fs-4 {
        font-size: 20px !important;
    }

    .custom-modal {
        width: 95vw !important;
    }

    .custom-card-2 {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 5px;
        border-radius: 10px;
    }

    .nevbar-moblie {
        font-size: 11px !important;
    }

    .nevbar-moblie button {
        font-size: 11px !important;
    }
}

/* -- BS */

.table {
    --bs-table-bg: none !important
}